import Model from './model';
import { OrderLine } from '@/application/models/ShoppingCart.js';
import dayjs from '@/plugins/dayjs.js';

export default class Order extends Model {
  id = 0;
  orderStatusId = 0;
  storeName = '';
  userName = '';
  countryName = '';
  date = '';

  mapForRequest() {
    return { ...this };
  }
}

export class CustomOrderLine extends Model {
  id = 0;
  title = '';
  productTypeId = 0;
  description = '';
  price = 0;
  quantity = 0;
  localPrice = 0;
  lineTotal = 0;
  localLineTotal = 0;
  itemNumber = '';
  createdAt = '';
  files = [];
  newFiles = [];
  addOns = []; //@TODO refactor this, a customer orderline doesn't even have addOns..
  deletedFiles = [];

  mapForRequest() {
    return {
      ...this,
      price: this.price * 100,
      localPrice: this.localPrice * 100,
      lineTotal: this.lineTotal * 100,
      localLineTotal: this.localLineTotal * 100,
      type: undefined,
      files: this.newFiles,
      newFiles: undefined,
    };
  }

  mapResponse(data) {
    data.price = (data.price / 100).toFixed(2);
    data.localPrice = (data.localPrice / 100).toFixed(2);
    data.lineTotal = (data.lineTotal / 100).toFixed(2);
    data.localLineTotal = (data.localLineTotal / 100).toFixed(2);
    data.type = 'custom';
    return super.mapResponse(data);
  }
}

export class OrderDetail extends Model {
  id = 0;
  amount = 0;
  store = {};
  status = [];
  orderStatusId = 0;
  orderLines = [];
  customOrderLines = [];
  approvers = [];
  createdAt = '';
  commentCount = 0;
  isLoaded = false;
  terms = null;
  financePlan = false;
  install = false;
  deliveryDate = '';
  installationDate = '';

  mapResponse(data) {
    data.amount = (data.amount / 100).toFixed(2);
    data.orderLines = data.orderLines.map(orderLine => new OrderLine().mapResponse(orderLine));
    data.customOrderLines = data.customOrderLines.map(orderLine => new CustomOrderLine().mapResponse(orderLine));
    data.orderStatusId = data.status[0].id;
    data.approvers = data.approvers.map(approver => {
      return {
        ...approver,
        createdAt: approver.createdAt !== null ? dayjs(approver.createdAt).format('DD-MM-YY') : 'geen',
      }
    });
    data.isLoaded = true;
    return super.mapResponse(data);
  }
}
