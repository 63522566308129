<template>
  <k-crud-layout :with-search="false" sheet-color>
    <template #header>
      <router-link :to="{name: 'orders.index'}" class="secondary--text">{{ $t('orders.pageTitle') }}</router-link>
      / {{ $t('orders.details.pageTitle', [orderId]) }}
    </template>
    <template #flow-actions>
      <v-btn class="mx-2" outlined tile @click="isCommentsSidebarOpen = true">
        <v-badge class="mr-4" overlap color="yellow" bordered dark v-if="orderDetails.commentCount">
          <v-icon>$comments</v-icon>
          <template #badge>
            <span class="black--text">{{ orderDetails.commentCount }}</span>
          </template>
        </v-badge>
        {{ $t('orders.details.comments') }}
      </v-btn>
      <v-btn v-if="orderDetails.orderStatusId === orderStatus.OFFER_REQUESTED" class="mx-2" color="secondary" tile @click="sendProposal">
        <v-badge class="mr-4" overlap color="yellow" bordered dark v-if="orderDetails.commentCount">
          <v-icon>$comments</v-icon>
          <template #badge>
            <span class="black--text">{{ orderDetails.commentCount }}</span>
          </template>
        </v-badge>
        {{ $t('orders.details.sendProposal') }}
      </v-btn>
      <OrderCommentsSideBar v-model="isCommentsSidebarOpen"
                            :able-to-place-comments="extendedDetails"
                            :order-id="$route.params.orderId"/>
    </template>

    <template #main-prepend v-if="orderDetails.isLoaded">
      <v-fade-transition>
        <k-display-group language-prefix="orders.display">
          <v-row class="mb-n8">
            <v-col md="4" sm="12" v-if="extendedDetails">
              <v-sheet class="pa-4 fill-height">
                <KSubtitle class="pa-0">{{ $t('orders.details.storeInfo') }}</KSubtitle>
                <v-row class="mt-2" no-gutters>
                  <v-col>
                    <k-text-display class="mb-3" field="storeName">
                      {{ orderDetails.store.name }}
                    </k-text-display>
                    <k-text-display class="mb-3" field="phoneNumber">
                      {{ orderDetails.store.phoneNumber }}
                    </k-text-display>
                    <k-text-display class="mb-3" field="dealerLevel">
                      {{ orderDetails.store.dealerLevel }}
                    </k-text-display>
                    <k-text-display class="mb-3" field="dealerCode">
                      {{ orderDetails.store.dealerCode }}
                    </k-text-display>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col md="4" sm="12">
              <v-sheet class="pa-4 fill-height">
                <KSubtitle class="pa-0">{{ $t('orders.details.addressData') }}</KSubtitle>
                <v-row class="mt-2" no-gutters>
                  <v-col cols="12" md="6">
                    <v-label>{{ $t('orders.details.address') }}</v-label>
                    <div>{{ orderDetails.store.shippingAddress.addressLine1 }}</div>
                    <div v-if="orderDetails.store.shippingAddress.addressLine2">
                      {{ orderDetails.store.shippingAddress.addressLine2 }}
                    </div>
                    <div>{{ orderDetails.store.shippingAddress.city }}</div>
                    <div>{{ orderDetails.store.shippingAddress.zipCode }}</div>
                    <div class="mb-4">{{ $t(`countries.${orderDetails.store.shippingAddress.countryId}`) }}</div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-label>{{ $t('orders.details.invoiceAddress') }}</v-label>
                    <div>{{ orderDetails.store.billingAddress.addressLine1 }}</div>
                    <div v-if="orderDetails.store.billingAddress.addressLine2">
                      {{ orderDetails.store.billingAddress.addressLine2 }}
                    </div>
                    <div>{{ orderDetails.store.billingAddress.city }}</div>
                    <div>{{ orderDetails.store.billingAddress.zipCode }}</div>
                    <div>{{ $t(`countries.${orderDetails.store.billingAddress.countryId}`) }}</div>
                  </v-col>
                  <v-col cols="12" v-if="orderDetails.store.phoneNumber">
                    <k-text-display width="20px" horizontal label="T">
                      {{ orderDetails.store.phoneNumber }}
                    </k-text-display>
                  </v-col>
                  <v-col cols="12" v-if="orderDetails.store.email">
                    <k-text-display width="20px" horizontal label="E">
                      {{ orderDetails.store.email }}
                    </k-text-display>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col md="4" sm="12" v-if="extendedDetails">
              <v-sheet v-if="orderDetails.orderStatusId === orderStatus.OFFER_REQUESTED" class="pa-4 fill-height">
                <KSubtitle class="pa-0">{{ $t('orders.details.deliveryInstallation') }}</KSubtitle>
                
                <div class="d-flex flex-column fill-height">

                  <KTextarea
                    v-for="input in ['deliveryDate','installationDate']"
                    :key="input" rows="1"
                    :field="$t(`orders.fields.${input}`)"
                    v-model="orderDetails[input]"
                    @input="datesInputChanged = true"
                    auto-grow
                  />

                  <v-btn
                    :disabled="!datesInputChanged"
                    color="secondary"
                    class="mt-auto mb-10"
                    @click="saveDates"
                    tile
                  >
                    {{ $t('actions.save') }}
                  </v-btn>
                </div>
              </v-sheet>
              <v-sheet v-else class="pa-4 fill-height">
                <KSubtitle class="pa-0">{{ $t('orders.details.approvers') }}</KSubtitle>
                <v-row class="mt-2" no-gutters>
                  <v-col>
                    <v-list class="pa-0" dense>
                      <v-label>{{ $t('orders.details.approvals') }}</v-label>
                      <v-list-item class="pa-0" v-for="approver in approvers" :key="approver.id">
                        <v-list-item-icon>
                          <v-icon :color="approvalIconColor(approver.isApproved)">
                            {{ approvalIcon(approver.isApproved) }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          {{ approver.user.userName }}
                          <span class="approver-created-at">{{ approver.createdAt }}</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </k-display-group>
      </v-fade-transition>
    </template>

    <template #view.list>
      <v-row>
        <v-col md="8" sm="12">
          <v-sheet class="pa-4">
            <v-btn color="secondary" tile class="ma-4" @click="handleOpenCreateDialog">
              {{ $t('orders.fields.addManualOrderLine') }}
            </v-btn>

            <ManualOrderLineForm v-model="createDialog"
                                :request="createRequest"
                                :values="createFormValues"
                                @change="reload"/>

            <v-data-table :headers="crudHeaders"
                          :items="detailsOrderLines"
                          :loading="isPending"
                          class="rounded-0"
                          :footer-props="{itemsPerPageOptions: [5,10,15,25],
                    itemsPerPageText: $t('global.$vuetify.dataFooter.itemsPerPageText'),
                    pageText: $t('global.$vuetify.dataFooter.pageText')}">
              <template #item="{item}">
                <tr>
                  <td rowspan="2">
                    <VImg v-if="item.type"
                          :src="require('@/assets/img/logo_impact_displays_negative.png')"
                          :lazy-src="require('@/assets/img/logo_impact_displays_negative-lazy.png')"
                          height="100px"
                          max-width="100px"
                          contain/>
                    <VImg v-else
                          :src="`${item.image}&type=thumbnail`"
                          :lazy-src="`${item.image}&type=lazy`"
                          height="100px"
                          max-width="100px"
                          contain/>
                  </td>
                  <td style="border-bottom: 0;">
                    <p>{{ item.name || item.title }}</p>
                    <template v-if="item.files">
                      <v-row no-gutters>
                        <!--                      @TODO why is this a v-alert?, why is everything in one file? this should be refactored!-->
                        <v-alert v-for="(file, index) in item.files"
                                :key="file.id"
                                height="50px"
                                width="50px"
                                outlined
                                tile
                                color="secondary"
                                @click="downloadFile(file)"
                                :class="{'ml-3' : index > 0}"
                                class="pa-0 cursor-pointer">
                          <v-responsive :aspect-ratio="1"
                                        max-height="50px"
                                        max-width="50px"
                                        class="justify-center align-center text-center">
                            <VImg :src="`${file.url}&type=thumbnail`"
                                  :aspect-ratio="1"
                                  min-height="35px"
                                  min-width="35px"
                                  :lazy-src="`${file.url}&type=lazy`"
                                  v-if="typeIsImage(file.type)"/>
                            <div v-else>
                              <v-icon large>{{ getFileTypeIcon(file.type) }}</v-icon>
                            </div>
                          </v-responsive>
                          <div class="text-truncate" style="width:50px" :title="file.name">{{ file.name }}</div>
                        </v-alert>
                      </v-row>
                    </template>
                  </td>
                  <td style="border-bottom: 0;">
                    {{ item.sku || (item.type ? item.itemNumber || 'N/A' : '') }}
                  </td>

                  <td style="border-bottom: 0" class="no-table-separator">
                    {{item.quantity}}
                  </td>

                  <td style="border-bottom: 0" class="no-table-separator">
                    <div class="mb-4">
                      {{ $n(item.price, 'currency') }}
                    </div>
                  </td>

                  <td rowspan="2" style="border-bottom: 0; text-align: right; width: 1%; white-space: nowrap; position: relative">
                    <div class="mb-4">{{ $n(item.lineTotal, 'currency') }}</div>
                    <template v-if="hasAddOns(item)">
                      <div>{{ $t('orders.details.addons') }}:</div>
                      <div class="pl-8">
                        <span>{{ $n(item.addOns.totalPrice, 'currency') }}</span>
                      </div>
                    </template>
                    <div class="custom-top-border"></div>
                  </td>
                  <td rowspan="2" style="width: 1%">
                    <div class="crud-actions">
                      <v-btn v-if="item.type"
                            color="text--secondary"
                            icon
                            @click="handleOpenUpdateDialog(item)">
                        <v-icon>$edit</v-icon>
                      </v-btn>
                      <v-btn v-if="!item.type"
                            :disabled="isPendingRemove.includes(item.id)"
                            color="text--secondary"
                            icon
                            @click.stop="$emit('remove', item)">
                        <v-icon>$delete</v-icon>
                      </v-btn>
                      <v-btn v-else
                            :disabled="isDeletingCustomOrder"
                            color="text--secondary"
                            icon
                            @click.stop="removeCustomOrderLine(item.id)">
                        <v-icon>$delete</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>

                <tr class="order-addons">
                  <td colspan="4" style="border-top: 0;">
                    <div v-for="product in item.addOns.products" :key="product.id" class="d-inline ma-3 text-center">
                      <v-badge :value="product.quantity" color="black" :content="product.quantity">
                        <VImg :src="`${product.image}&type=thumbnail`"
                              :lazy-src="`${product.image}&type=lazy`"
                              height="100px"
                              max-width="100px"
                              contain/>
                        {{ product.sku }}
                      </v-badge>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <ManualOrderLineForm v-model="updateDialog"
                                is-update-form
                                :request="updateRequest"
                                :values="updateFormValues"
                                @change="reload"/>
          </v-sheet>
        </v-col>
        <v-col md="4" sm="12">
          <v-sheet class="pa-4 fill-height">
            <KSubtitle class="pa-0">{{ $t('orders.details.checkout') }}</KSubtitle>
            <KCheckbox v-model="orderDetails.install"
                       readonly
                       hide-details
                       :label="$t('orders.display.displayInstall')"/>
            <KCheckbox v-model="orderDetails.financePlan"
                       readonly
                       hide-details
                       :label="$t('orders.display.useOfFinancePlan')"/>
            <KCheckbox v-model="orderDetails.terms"
                       readonly
                       hide-details
                       :label="$t('orders.display.termsAndConditions')"/>

            <DisplayTotalAmount v-model="totalAmount"/>
          </v-sheet>
        </v-col>
      </v-row>
    </template>

  </k-crud-layout>
</template>

<script lang="js">
import {CustomOrderLine, OrderDetail} from '@/application/models/Order.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';
import OrderCommentsSideBar from '@/modules/orders/components/OrderCommentsSideBar.vue';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import {create, remove, update} from '@/modules/orders/api/customOrderLine.js';
import { updateOrder, sendProposal } from '@/modules/orders/api';
import ManualOrderLineForm from '@/modules/orders/components/ManualOrderLineForm.vue';
import {getFileTypeIcon, typeIsImage} from '@/application/util/displayFile.js';
import DisplayTotalAmount from '@/modules/orders/components/DisplayTotalAmount.vue';
import downloadFile from '@/application/util/downloadFile.js';
import {get} from '@/application/api/implementations/app';
import orderStatus from '@/application/enums/orderStatus.js';
import EventBus from '@/application/eventBus';

export default {
  name: 'DetailLayout',
  components: {
    DisplayTotalAmount,
    KCheckbox,
    OrderCommentsSideBar,
    KDisplayGroup,
    KTextDisplay,
    KSubtitle,
    KCrudLayout,
    ManualOrderLineForm,
    KTextarea
  },
  data() {
    return {
      isCommentsSidebarOpen: false,
      createFormValues: new CustomOrderLine(),
      updateFormValues: new CustomOrderLine(),
      createDialog: false,
      updateDialog: false,
      isDeletingCustomOrder: false,
      datesInputChanged: false,
    };
  },
  props: {
    crudHeaders: {
      type: Array,
      required: true,
    },
    orderId: {
      type: [Number, String],
      required: true,
    },
    orderDetails: {
      type: OrderDetail,
      required: true,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
    isPendingSubmit: {
      type: Boolean,
      default: false,
    },
    isPendingRemove: {
      type: Array,
      default: () => [],
    },
    extendedDetails: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    detailsOrderLines() {
      return (this.orderDetails.orderLines).concat(this.orderDetails.customOrderLines);
    },
    totalAmount() {
      return this.orderDetails?.amount || 0;
    },
    approvers() {
      return this.orderDetails.approvers;
    },
    orderStatus() {
      return orderStatus;
    }
  },
  methods: {
    getFileTypeIcon,
    typeIsImage,
    approvalIcon(isApproved) {
      if (isApproved === null) return '$unknown';
      if (isApproved === true) return '$success';
      return '$errorCircle';
    },
    approvalIconColor(isApproved) {
      if (isApproved === null) return 'gray';
      if (isApproved === true) return 'green';
      return 'red';
    },
    hasAddOns(item) {
      return item.addOns?.products?.length > 0;
    },
    handleOpenCreateDialog() {
      this.createFormValues = new CustomOrderLine();
      this.createDialog = true;
    },
    handleOpenUpdateDialog(item) {
      this.updateFormValues = item;
      this.updateDialog = true;
    },
    createRequest() {
      return create(this.orderId, this.createFormValues);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    async removeCustomOrderLine(customOrderId) {
      this.isDeletingCustomOrder = true;
      await remove(customOrderId);
      this.isDeletingCustomOrder = false;
      this.reload();
    },
    validate () {
      this.$refs.dateForm.validate();
    },
    reload() {
      this.$emit('reload');
    },
    downloadFile(file) {
      downloadFile(get(file.url, { responseType: 'blob' }), file.name);
    },
    async sendProposal(){
      await sendProposal(this.orderId);
      this.reload();

    },
    async saveDates(){
      try {
        await updateOrder(this.orderId, {
          deliveryDate: this.orderDetails.deliveryDate, 
          installationDate: this.orderDetails.installationDate
        })
        
        this.datesInputChanged = false

        EventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('actions.resourceUpdated', { resource: 'Order' }),
        });
      } catch(e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.generalError'),
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table-header > tr > th {
    white-space: nowrap;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    vertical-align: top;
    padding-top: 8px
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: inherit;
  }

  .approver-created-at {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.75em;
    display: contents;
  }

  tbody tr:not(:first-child) td .custom-top-border {
    position:   absolute;
    top:        -1px;
    height:     1px;
    background: rgba(0, 0, 0, 0.12);
    left:       -1px;
    right:      -1px;
  }
}
</style>
